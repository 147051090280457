<template>
    <div class="category">


            <div class="left">

                <div :class="index===active?'menu-item menu-active':'menu-item'"  v-for="(item,index) in categories" :key="index" @click="onMenuChange(index)">
                    {{item.name}}
                </div>
            </div>

        <div class="right">


            <div style="margin-left: 10px;margin-right: 10px">
            <div class="grid2" v-if="children.length>0">
                <div class="subMenu-item" v-for="(item,index) in children" :key="index" @click="subMenuClick(item)">
                    <img :src="item.icon"/>
                    <div>{{item.name}}</div>
                </div>

            </div>
            <empty v-if="childrenEmpty"/>

        </div>
        </div>

    </div>

</template>

<script>
    import Empty from '@/components/Empty'
    export default {
        name: "Category",
        components:{
            Empty
        },
        data(){
            return {
                categories:[],
                active:0,
                children:[],
                childrenEmpty:false
            }
        },
        watch:{
            "$store.state.category.categories":{
                deep:true,
                handler:function(newVal){
                    this.categories=newVal;
                    if(newVal.length>0){
                     this.children=newVal[0].children;
                     this.childrenEmpty= this.children.length===0;
                    }
                }
            }
        },

        created(){

            this.categories=this.$store.state.category.categories;
            if(this.categories.length>0){
               this.children=this.categories[0].children;
               this.childrenEmpty= this.children.length===0;
            }
        },

        methods:{
            onMenuChange(index){

                this.active=index;
                this.children=this.categories[index].children;
                this.childrenEmpty= this.children.length===0;
            },
            subMenuClick(item){

                //防止重复点击
                if(item.id===this.$store.state.user.category.id){
                    return
                }
                this.$store.commit('user/saveCategory',{id:item.id,title:item.name});
                this.$emit('onClose')
                this.bus.$emit(this.ON_CATEGORY_CHANGE,item.id)
            }

        }
    }
</script>

<style lang="less" scoped>

    .category {

        display: flex;

        .left{
            background-color:#F8F8F8;
            border-radius: 6px;
            flex-shrink: 0;
            overflow-y: scroll;
            max-height: 300px;
            padding-bottom: 10px;
            -ms-overflow-style: none;
        }
        .left::-webkit-scrollbar{
            width:0 !important;
            display: none;
            overflow: -moz-scrollbars-none;
        }

        .menu-item {
            padding: 15px 20px;
            font-size: 17px;
            position: relative;
            cursor: pointer;

        }

        .menu-active {
            background-color: #EFEFF4;
        }
        .menu-active::before {

            content: '';
            position: absolute;
            top: 20px;
            left: 5px;
            height: 15px;
            width: 4px;
            border-radius: 2px;
            background-color: #3B89FF;

        }

        .right{
            width: 100%;
            overflow-y: scroll;
            max-height: 300px;
            padding-bottom: 10px;
            -ms-overflow-style: none;
        }
        .right::-webkit-scrollbar{
            width:0 !important;
            display: none;
            overflow: -moz-scrollbars-none;
        }
        .subMenu-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #F8F8F8;
            border-radius: 6px;
            padding: 10px 15px;
            color: #000000;



        }
        .subMenu-item img {
            width: 40px;
            height: 40px;
        }
        .subMenu-item div {
            font-size: 13px;
        }

        .grid2 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            font-size: 13px;
            cursor: pointer;

        }
        .grid3 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            font-size: 13px;
            cursor: pointer;

        }
    }

</style>
