<template>
    <div>
        <div class="footer">
            <div class="content">
                <div>
                <div style="font-size: 22px;font-weight: bold;">联系我们</div>
                <div style="display: flex;align-items: center;margin-top: 15px">
                    <div style="font-size: 17px">服务热线</div>
                    <div style="margin-left: 10px;font-size: 22px;font-weight: bold">0851-38157660</div>
                </div>
                    <div style="display: flex;align-items: center;font-size: 17px;margin-top: 15px">
                        <div>工作时间</div>
                        <div style="margin-left: 10px">周一至周五 9:00 - 18:00</div>
                    </div>
                <div  style="display: flex;align-items: center;font-size: 17px;margin-top: 15px">
                    <div>联系地址</div>
                    <div style="margin-left: 10px">贵州省贵阳市观山湖区长岭南路摩根中心A座18层</div>
                </div>
                </div>
                <div>
                    <img src="../assets/img/home/qrcode.png"/>
                    <div style="font-size: 12px">扫描二维码 进入小程序</div>
                </div>
            </div>
            <div class="line"></div>
            <div class="copyRight">
                CopyRight© {{year}} 版权所有 ICP备案号：<a href="http://beian.miit.gov.cn/">黔ICP备19003293号-1</a>
                <img style="margin-left: 10px" src="../assets/img/ba.png"/><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52990002000058">贵公网安备 52990002000058号</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        data(){

            return{
                year:''
            }
        },
        created(){

            this.year = new Date().getFullYear()

        },
        methods:{



        }

    }
</script>

<style scoped>
@import url(../assets/css/Footer.css);

</style>
