<template>
    <div class="empty" :style="{height:height}">
        <el-empty :image="require('../assets/img/empty.png')"></el-empty>
    </div>
</template>

<script>

    export default {
        name: "Empty",
        props:{
            height:{
                type:String,
                default:'auto'
            }
        }
    }
</script>

<style lang="less" scoped>

    .empty{
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>
