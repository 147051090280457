<template>
    <div>
        <div class="header">
            <div class="content">
                <div class="left">
                    <img class="logo" src="../assets/img/logo.png"  alt=""
                         @click="handelpush('home')">
                    <el-popover
                            placement="bottom"
                            width="400"
                            v-model="visible"
                            trigger="hover">
                        <div style="padding: 10px">
                            <Category @onClose="visible=false"/>
                        </div>
                        <div class="category" slot="reference">

                        {{$store.state.user.category.title||'请选择'}} <i class="el-icon-caret-bottom"></i>

                        </div>

                    </el-popover>


                </div>
                <div class="right">
                    <el-dropdown trigger="hover" @command="handleCommand">
                        <div class="el-dropdown-link" >
                            <img class="avatar" :src="avatar||require('../assets/img/user.png')"/> <span>{{name||username}}</span>

                            <i class="el-icon-arrow-down el-icon--right"></i>

                        </div>
                        <el-dropdown-menu slot="dropdown" class="header-dropdown">
                            <el-dropdown-item  command="userInfo?active=0"><div class="item"><div class="title">个人中心 </div><i class="el-icon-arrow-right"></i></div></el-dropdown-item>
                            <el-dropdown-item  command="userInfo?active=1"><div class="item"><div class="title">我的课程</div><i class="el-icon-arrow-right"></i></div> </el-dropdown-item>
                            <el-dropdown-item  command="userInfo?active=2"><div class="item"><div class="title">我收藏的文章</div><i class="el-icon-arrow-right"></i></div> </el-dropdown-item>
                            <el-dropdown-item  command="notice"><div class="item"><div class="title">通知公告</div> <i class="el-icon-arrow-right"></i></div></el-dropdown-item>
                            <el-dropdown-item  command="company"><div class="item"><div class="title">单位介绍</div><i class="el-icon-arrow-right"></i></div> </el-dropdown-item>
                            <el-dropdown-item  command="exit" divided><div class="item"><div class="title">切换登录</div><img class="el-icon-arrow-right" style="width: 12px" src="../assets/img/exit.png"></div></el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Category from '@/components/Category'
    export default {
        components:{
            Category
        },
        data(){

            return {
                visible:false,
                avatar:'',
                name:'',
                username:''
            }

        },
        watch:{

            '$store.state.user.userInfo':{
                deep:true,
                handler:function (newVal) {

                    this.avatar=newVal.headImg
                    this.username=newVal.username
                    this.name=newVal.name

                }

            }

        },
        created(){
            this.avatar=this.$store.state.user.userInfo.headImg;
            this.username=this.$store.state.user.userInfo.username;
            this.name=this.$store.state.user.userInfo.name;
        },

        methods: {


            onMenuChange(index){

                this.active=index;


            },


            handelpush(i,ev) {
                this.$router.push({
                    path :'/'+i
                })
                // ev.path[0].class
                // ev.path[0].className='active'
            // console.log(ev.path[0].addClass('color'));
            },
             handleCommand(i) {

                if(i==='exit'){
                    //退出登录
                    this.$store.dispatch('user/logout')
                }else{
                    this.$router.push({
                        path :'/'+i
                    })
                }


             }
        },
    }
</script>

<style lang="less" scoped>
@import url(../assets/css/Header.css);

  /deep/ .el-dropdown-menu__item{
    
    .item{
        display: flex;
        align-items: center;

        .title{
            flex: 1;
        }

    }
  }

</style>
