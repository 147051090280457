<template>
    <div class="statistics">

        <Header/>
        <div class="box">
            <div class="nav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>统计</el-breadcrumb-item>
                </el-breadcrumb>
               <CategoryTitle title="统计" desc="想要统计其他科目"></CategoryTitle>
            </div>
            <div class="content">

                <div class="count">
                    <div class="quest-count">
                        <div style="display: flex;align-items: center">
                            <div class="value">{{topicCount}}</div>
                            <div class="right" @click="$router.push('/randomPractice')">去练习<i class="el-icon-arrow-right"></i></div>
                        </div>
                        <div class="desc">累计做题  超越{{topicSurpassRate}}的人</div>
                    </div>
                    <div class="rate">
                        <div style="display: flex;align-items: flex-end">
                            <div class="value">{{accuracyRate}}</div>
                            <div style="margin-left: 5px">正确率</div>
                        </div>
                        <div class="desc">继续保持！超越{{accuracySurpassRate}}的人</div>
                    </div>
                </div>

                <div class="table-title">
                    <div class="title">练习明细</div>
                    <div class="desc">最近30天累计练习23天</div>
                </div>
                <div>
                    <el-table
                            :data="records"
                            stripe
                            style="width: 100%">
                        <el-table-column
                                prop="date"
                                label="日期"
                               
                                >
                        </el-table-column>
                        <el-table-column
                                prop="time"
                                label="练习时长"
                            
                                >
                        </el-table-column>
                        <el-table-column
                                prop="num"
                                label="练习次数"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="percentage"
                                align="center"
                                label="正确率">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page">
                    <el-pagination
                            @change="handelChange"
                            :hide-on-single-page="true"
                            background
                            layout="total, prev, pager, next"
                            :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <Footer/>

    </div>


</template>

<script>
    import Header from "../components/Header.vue"
    import CategoryTitle  from  '@/components/CategoryTitle'
    import Footer from '../components/Footer'
    import {GetStatisticsCount,GetPracticeRecords} from '@/api/text'
    export default {
        name: "Statistics",
        components:{
            Header,
            CategoryTitle,
            Footer
        },
        data(){
            return{
                     //总做题数
                    topicCount:0,
                    //做题超越率
                    topicSurpassRate:'98%',
                    //正确率
                    accuracyRate:'81%',
                    //正确率超越率
                    accuracySurpassRate:'90%',
                    records:[],
                    page:1,
                    pageSize:10,
                    total:1
            }
        },
        created(){

            this.getStatisticsData();
            this.getPracticeRecordsData();
            //监听科目改变
            this.bus.$on(this.ON_CATEGORY_CHANGE,()=>{
                this.getStatisticsData();
                this.getPracticeRecordsData();
            })

        },
        methods:{

            //加载统计数据
            getStatisticsData(){

                GetStatisticsCount(this.$store.state.user.category.id).then(res=>{

                    this.topicCount=res.data.count;
                    this.topicSurpassRate=res.data.countPercentage;
                    this.accuracyRate=res.data.passPercentage
                    this.accuracySurpassRate = res.data.exceedPercentage
                })

            },
            //加载明细列表
            getPracticeRecordsData(){
                GetPracticeRecords(this.pageSize,this.page,this.$store.state.user.category.id).then(res=>{

                    this.records=res.data
                })
            },
            //分页
            handelChange(val){

                this.page=val;
                this.getPracticeRecordsData();
            }
        },
        beforeDestroy(){
            //解绑
            this.bus.$off(this.ON_CATEGORY_CHANGE)
        }
    }
</script>

<style lang="less" scoped>

    .statistics{

        background-color: #F3F4F6;

        .box{
            width: 1200px;
            margin: 20px auto;

            .nav{
                .title{
                    margin-top: 24px;
                    font-size: 34px;

                }
                .desc{
                    color: rgba(60, 60, 67, 0.60);
                    font-size: 14px;
                }
            }

            .content{
                margin-top: 30px;
                background: #FFFFFF;
                border-radius: 10px 10px 10px 10px;
                opacity: 1;
                padding: 20px;
                .count{
                    width: 100%;
                    height: 147px;
                    background: linear-gradient(0deg, #EFF5FF 0%, #DCEAFF 11%, #E9F2FF 48%, #F7FCFF 100%);
                    border-radius: 10px;
                    display: flex;
                    align-items: center;

                    .quest-count{
                        width: 100%;
                        height: 96px;
                        background-color: #fff;
                        border-radius: 4px;
                        margin-left: 16px;
                        display: flex;

                        flex-direction: column;
                        .value{
                            margin-top: 12px;
                            font-size: 34px;
                            font-weight: 500;
                            margin-left: 10px;
                            flex: 1;
                        }
                        .right{

                            margin-right: 10px;
                            color: rgba(60,60,67,0.6);
                            cursor: pointer;
                        }
                        .desc{
                            margin-left: 10px;
                            font-size: 13px;
                            font-weight: 400;
                            color: rgba(60,60,67,0.6);
                        }

                    }
                    .rate{
                        width: 100%;
                        background: #F2FFF7;
                        height: 96px;
                        border: 0px solid #00C94C;
                        border-radius: 4px;
                        margin-left: 28px;
                        margin-right: 16px;
                        .value{
                            margin-top: 12px;
                            font-size: 34px;
                            font-weight: 500;
                            margin-left: 10px;
                            color: #00C94C;

                        }
                        .desc{
                            margin-left: 10px;
                            font-size: 13px;
                            color: rgba(60,60,67,0.6);
                        }
                    }
                }

                .table-title{
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    .title{
                        font-size: 17px;
                        font-weight: bold;
                    }
                    .desc{
                        margin-left: 10px;
                        font-size: 13px;
                        color: rgba(60,60,67,0.6);
                    }
                }

                .page{
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;
                }
            }
        }
    }

</style>
